//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.65);
    .box-shadow(none);
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}
.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}
// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


// CUSTOM
.btn-blue,
.btn-green,
.btn-white,
.btn-grey,
.btn-gold {
  .transition(background-color 200ms);

    //icons
    img {
      width: 13px;
      height: 13px;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }

    a {
      text-decoration: none;
    }
}

.btn-blue,
.btn-green {
  a {
    color: @brand-white;
  }
}

.btn-white,
.btn-grey {
  a {
    color: @brand-black;
  }

  .btn-gold {
    a {
      color: @btn-gold-color;
    }
  }
}

.btn-blue {
  .button-variant(@btn-blue-color; @btn-blue-bg; lighten(@btn-blue-border, 32%));

  &:hover {
    background: lighten(saturate(@btn-blue-bg, 20%), 10%);
  }

  &:active {
    background: lighten(saturate(@btn-blue-bg, 25%), 20%);
    box-shadow: inset 0 0 5px 0 @btn-blue-bg;
  }
}
.btn-green {
  .button-variant(@btn-green-color; @btn-green-bg; lighten(@btn-green-border, 32%) );

  &:hover {
    background: lighten(saturate(@btn-green-bg, 10%), 10%);
  }

  &:active {
    background: lighten(saturate(@btn-green-bg, 25%), 20%);
    box-shadow: inset 0 0 5px 0 @btn-green-bg;
  }
}
.btn-grey,
.btn-gray {
  .button-variant(@btn-grey-color; @btn-grey-bg; @btn-grey-border);

  &:hover {
    background: lighten(@btn-grey-bg, 5%);
  }

  &:active {
    background: lighten(@btn-grey-bg, 5%);
    box-shadow: inset 0 0 5px 0 @btn-grey-bg;
  }
}
.btn-white {
  .button-variant(@btn-white-color; @btn-white-bg; @btn-white-border);

  &:hover {
    background: fade(@brand-white, 60%);
  }

  &:active {
    background: @btn-white-bg;
  }
}
.btn-gold {
  .button-variant(@btn-gold-color; @btn-gold-bg; @btn-gold-border);

  &:hover {
    background: lighten(saturate(@btn-gold-bg, 20%), 10%);
  }

  &:active {
    background: lighten(saturate(@btn-gold-bg, 25%), 20%);
    box-shadow: inset 0 0 5px 0 @btn-gold-bg;
  }
}
// CUSTOM
// 
// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-xxl {
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-xxl; @line-height-xl; @btn-border-radius-large);
  font-weight: bold;

  @media(max-width: @widthMobile) {
    font-size: (@font-size-xxl * 0.6);
  }
}
.btn-xl {
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-xl; @line-height-xl; @btn-border-radius-large);
  font-weight: bold;
}
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}



// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
